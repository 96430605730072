<template>
    <div class="BatchPlan">
        <div class="padd20 borbut20" style="position: relative;">
            <div class="topSearchSou">
                <div class="searchdan">
                    <!-- <div class="title">访问时间：</div> -->
                    <el-input v-model="name" placeholder="请输入名字" class="params"></el-input>
                    <el-input v-model="account" placeholder="请输入手机号" class="params"></el-input>
                    <el-select v-model="status" placeholder="选择状态" style="margin-left:20px">
                        <el-option v-for="item in status_list" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- <el-date-picker v-model="pickerTime" type="date" placeholder="选择日期" @change="selectPickerTime" >
                    </el-date-picker> -->
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <div class="TableList padd20" style="padding-top:10px">
            <div class="duoshao">
                <div class="title">批量计划用户管理
                    <el-button type="primary" icon="el-icon-plus" @click="add()">添加用户</el-button>
                </div>
                <!-- <div class="lvtotal">
                    总条数：{{lvtotal}} 条
                </div> -->
                <div class="lvtotal">总条数：{{lvtotal}} 条 <div class="lvtotal riri" style="margin-left:5px">
                        每页显示:
                        <el-select v-model="per_page" placeholder="10" @change="selectTrigger()" style="margin-left:20px;margin-right:20px;width:80px">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                        条
                    </div>
                </div>
            </div>
            <el-table ref="filterTable" :data="ErShopList" style="width: 100%">
                <el-table-column prop="name" label="姓名">
                    <!-- <template slot-scope="scope">
                        <div>{{scope.row.name}}</div>
                    </template> -->
                </el-table-column>
                <el-table-column prop="ad_user" label="访问方式">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_inner==1">内部访问</div>
                        <div v-if="scope.row.is_inner==2">外部访问</div>
                        <div v-if="scope.row.is_inner==3">内部推荐访问</div>
                    </template>
                </el-table-column>
                <el-table-column prop="account" label="手机号">
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" @change="onswitch(scope.row)" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="your_kind_balance" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="onDetails(scope.row)" style="margin-left:0px;">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <User ref="User"></User>
        <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next, jumper" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
    </div>

</template>

<script>
import User from "./user.vue";
export default {
    data() {
        return {
            uri_name: "",
            paramData: {}, //表格参数
            shopid: "", //参数
            pickerTime: "", //日期参数
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            shop_title: "",
            ErShopList: [], //数据
            particularsList: [], //详情数据
            detailsTime: "", //详情日期
            // particulars: {
            //     //详情分页
            //     per_page: 8, //一页多少条
            //     lvtotal: null, //共几条数据
            //     paramPage: 1, //表格当前第几页
            // },
            detailsData: {}, //  详情数据
            params: "", //搜索参数
            particularsID: "",
            name: "",
            account: "",
            options: [
                {
                    value: 25,
                    label: "25",
                },
                {
                    value: 50,
                    label: "50",
                },
                {
                    value: 100,
                    label: "100",
                },
            ],
            status: "",
            status_list: [{
                value: '0',
                label: '已关闭'
            }, {
                value: '1',
                label: '已开启'
            }],
        };
    },

    mounted() {
        this.getAdUserList();
    },
    components: {
        User,
    },
    methods: {
        onswitch(data) {
            var param = {
                id: data.id,
                status: data.status
            };
            this.$service.post(
                this.$api.createAdUser,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.$message({ message: '修改成功', type: 'success' })
                    }
                }
            );
        },
        //添加
        add() {
            this.$refs.User.onDialog();
        },
        //编辑
        onDetails(data) {
            this.$refs.User.onDialog(data);
        },
        //显示条数改变
        selectTrigger() {
            this.paramPage = 1;
            this.paramData.per_page = this.per_page;
            this.getAdUserList();
        },
        // 获取昨天
        // getDay(day) {
        //     var today = new Date();
        //     var targetday_milliseconds =
        //         today.getTime() + 1000 * 60 * 60 * 24 * day;
        //     today.setTime(targetday_milliseconds); //注意，这行是关键代码
        //     var tYear = today.getFullYear();
        //     var tMonth = today.getMonth();
        //     var tDate = today.getDate();
        //     tMonth = this.doHandleMonth(tMonth + 1);
        //     tDate = this.doHandleMonth(tDate);
        //     return tYear + "-" + tMonth + "-" + tDate;
        // },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        selectPickerTime() {
            if (this.pickerTime != null) {
                this.pickerTime = this.dateFtt("yyyy-MM-dd", this.pickerTime);
            }
        },
        //页面搜索
        onSearch() {
            this.paramData = {
                account: this.account, //搜索日期
                name: this.name,
                status:this.status
            };
            this.paramPage = 1;
            this.getAdUserList();
        },
        // 点击图片
        onImg(img) {
            window.open(
                "https://user.erlangcha.com/advances/Advimage?imageurl=" + img
            );
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val;
            this.getAdUserList();
        },
        // 详情分页
        particularsChange(val) {
            this.particulars.paramPage = val;
            this.onParticulars();
        },
        //数据列表
        getAdUserList() {
            this.ErShopList = [];
            var param = this.paramData;
            param.page = this.paramPage;
            param.per_page = this.per_page;
            // param.date = this.pickerTime;
            this.$service.get(this.$api.getAdUserList, param, (res) => {
                if (res.code == "200") {
                    console.log(res)
                    this.ErShopList = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.BatchPlan {
    .lvtotal {
        line-height: 33px;
    }
    .img {
        width: 40px;
        height: 40px;
    }
    .Souxiangqing {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
    .flex {
        display: flex;
        justify-content: space-around;
        div {
            width: 160px;
            height: 80px;
            text-align: center;
            line-height: 80px;
            font-size: 18px;
        }
    }
    .pan {
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1 !important;
        overflow: hidden;
    }
}
</style>
<style lang="scss">
.BatchPlan {
    .el-dialog__header {
        background: #0075f7;
        padding-bottom: 15px;
    }
    .el-dialog__title {
        color: #fff;
    }
    .el-input__icon {
        line-height: 35px;
    }
    .params {
        width: 180px !important;
        margin-left: 20px;
    }
    .el-table td,
    .el-table th {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .is-in-pagination {
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center !important;
    }
    .el-pagination__jump .el-input__inner {
        text-align: center !important;
    }
    .img {
        width: 100px !important;
        height: 100px !important;
    }
}
.testtooltip {
    width: 200px;
}
</style>