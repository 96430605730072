<template>
    <el-dialog :title="id == '' ? '添加用户' : '编辑用户'" :visible.sync="confitionDialog" append-to-body custom-class='class-dialog' width="500px" @close="onCancel" :close-on-click-modal='false' :close-on-press-escape='false'>
        <div class="dialog-inside userDialog">
            <div class="sunbox">
                <div class="sunbox-text">姓名：</div>
                <div class="sunbox-content">
                    <el-input v-model="name" placeholder="请输入用户姓名"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text">手机号：</div>
                <div class="sunbox-content">
                    <el-input v-model="account" placeholder="请输入手机号"></el-input>
                </div>
            </div>
            <div class="sunbox">
                <div class="sunbox-text">访问方式：</div>
                <div class="sunbox-content">
                    <el-select v-model="is_inner" placeholder="请选择">
                        <el-option v-for="(item,key) in roleList" :key="key" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
            <el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
        </div>
    </el-dialog>

</template>

<script>

export default {
    data() {
        return {
            confitionDialog: false,
            id: "",
            name: '',
            account: '',
            is_inner: '',
            roleList: [{
                value: '1',
                label: '内部访问'
            }, {
                value: '2',
                label: '外部访问'
            }, {
                value: '3',
                label: '内部推荐访问'
            }],//用户角色列表
        };
    },

    mounted() {

    },

    methods: {
        //提交
        onSubmit() {
            if (this.name == '') { this.$message({ message: "请填写用户姓名", type: "warning" }); return; }
            if (this.account == '') { this.$message({ message: "请填写手机号", type: "warning" }); return; }
            if (this.is_inner == '') { this.$message({ message: "请选择访问方式", type: "warning" }); return; }
            var param = {
                id: this.id ? this.id : "",
                name: this.name,
                account: this.account,
                is_inner: this.is_inner
            }
            this.$service.post(this.$api.createAdUser, param, (res) => {
                if (res.code == '200') {
                    this.$message({ message: '添加用户成功', type: 'success' })
                    this.$parent.getAdUserList()
                    this.onCancel()
                }
            })
        },
        // 获取用户角色列表
        ongetRoleList() {
            this.$service.get(this.$api.getRoleList, {}, (res) => {
                if (res.code == '200') {
                    this.roleList = res.data
                }
            })
        },
        //取消
        onCancel() {
            this.confitionDialog = false
            this.name = '',
                this.account = ''
        },
        //添加用户弹窗
        onDialog(data) {
            this.confitionDialog = true;
            console.log(data)
            if (data != undefined) {
                this.id = data.id;
                this.is_inner = String(data.is_inner);
                this.account = data.account;
                this.name = data.name;
            } else {
                this.is_inner = "";
                this.account = "";
                this.name = "";
            }

        },
    },



    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>